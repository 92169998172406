import {useHubQuery} from '../../QueryProvider'

export const usePersistedFilters = (filterType: string) => {
  return useHubQuery('persistedFilters', [filterType], {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!filterType
  })
}
