export type Dictionary<T> = Record<string, T>

export enum BusinessLineType {
  CEM = 'CEM',
  RMC = 'RMC',
  AGG = 'AGG'
}
export type BusinessLineTypes = keyof typeof BusinessLineType

export enum BrandingCountry {
  AU = 'AU',
  CA = 'CA',
  HK = 'HK',
  RU = 'RU',
  US = 'US'
}

export type BrandingCountries = keyof typeof BrandingCountry
export type Optional<T> = T | undefined | null

export interface FilterDefinition {
  name: string
  label: string
  isActive: boolean
  isMandatory?: boolean
}
