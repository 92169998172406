import {useMutation} from 'react-query'

import {mutations} from '../../mutations'
import {hubQueryClient} from '../../QueryProvider'

export const usePersistFilters = () => {
  return useMutation(mutations.persistFilters, {
    onMutate: async ({filterType}) => {
      await hubQueryClient.invalidateQueries(['persistedFilters', filterType])
    },
  })
}
