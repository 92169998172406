import {orderBy} from 'lodash'

import {api} from '../../App.global'
import {DistributedCacheResponse} from '../../DistributedStorage/type'
import {OrderRequestStatus} from '../../OrderIntake/declarations/OrderIntake.enums'
import {
  Contact,
  OptimizedOption,
  OrderIntakeMaterialOptionPayload,
  OrderRequestResponse,
  PersistedOrderRequest,
  SalesOfficeData,
  SlotConfiguration,
  OrderCancellationOptions,
  CancellationRequestStatus,
  NotificationSettings
} from '../../OrderIntake/declarations/types'
import {mapOptimizedOptionsToLegacy} from '../../OrderIntake/Options/mapper'
import {PersistedRequestForQuoteFormValues} from '../../OrderIntake/RequestForQuoteFormValues'
import {
  ORDERS_MANAGEMENT_API_PATH,
  ORDER_REQUESTS_API_PATH,
  REQUEST_FOR_QUOTES_API_PATH
} from '../../Organisms/OrderIntake/OrderIntake.action'
import {FilterDefinition} from '../types'

import {AsyncFn} from './api'
import {ScheduledExportType} from './types'

export interface Queries {
  orderRequestsOptions: AsyncFn<
    [
      {
        customerId?: string
        applyMaterialEnteredDescription: boolean
        applyContractItemDescription: boolean
      }
    ],
    OrderIntakeMaterialOptionPayload[]
  >
  failedOrderRequests: AsyncFn<[customerId?: string], OrderRequestResponse[]>
  notValidatedOrderRequests: AsyncFn<[customerId?: string], OrderRequestResponse[]>
  persistedOrderRequests: AsyncFn<[siteNumber: string], PersistedOrderRequest[]>
  persistedContact: AsyncFn<[siteNumber: string], Contact>
  slotConfigurations: AsyncFn<[], SlotConfiguration[]>
  requestForQuotesRegions: AsyncFn<[country: string], SalesOfficeData[]>
  orderCancellationOptions: AsyncFn<[orderId: string], OrderCancellationOptions>
  cancellationRequestStatus: AsyncFn<[orderId: string], CancellationRequestStatus>
  persistedRequestForQuote: AsyncFn<
    [siteNumber: string, businessLine: string],
    PersistedRequestForQuoteFormValues
  >
  notificationSettings: AsyncFn<[], NotificationSettings[]>
  scheduledExports: AsyncFn<[], ScheduledExportType[]>
  persistedFilters: AsyncFn<[filterType: string], FilterDefinition[]>
}
export const queries: Queries = {
  orderRequestsOptions: async ({
    customerId,
    applyMaterialEnteredDescription,
    applyContractItemDescription
  }) => {
    const path = `${ORDER_REQUESTS_API_PATH}/options?customerId=${customerId}`
    const response = await api.get<OptimizedOption[]>(path)
    const options = mapOptimizedOptionsToLegacy(
      response.data,
      applyMaterialEnteredDescription,
      applyContractItemDescription
    )

    return options
  },
  failedOrderRequests: async (customerId) => {
    const path = `${ORDER_REQUESTS_API_PATH}?customerId=${customerId}&status=${OrderRequestStatus.FAILED}&isBannerDismissed=${false}`
    const response = await api.get<OrderRequestResponse[]>(path)
    return response.data
  },
  notValidatedOrderRequests: async (customerId) => {
    const path = `${ORDER_REQUESTS_API_PATH}?customerId=${customerId}&status=${OrderRequestStatus.NOT_VALIDATED}`
    const response = await api.get<OrderRequestResponse[]>(path)
    return response.data
  },
  persistedOrderRequests: async (siteNumber) => {
    const path = `distributeduserstorage?keyIdentifier=orderIntake-orderRequests-${siteNumber}`
    const response = await api.get<DistributedCacheResponse>(path)

    if (!response.data.value) return

    return JSON.parse(response.data.value)
  },
  persistedContact: async (siteNumber) => {
    const path = `distributeduserstorage?keyIdentifier=orderIntake-contact-${siteNumber}`
    const response = await api.get<DistributedCacheResponse>(path)

    if (!response.data.value) return

    return JSON.parse(response.data.value)
  },
  slotConfigurations: async (): Promise<SlotConfiguration[]> => {
    const path = '/SlotConfiguration/configurations?country=GB'
    const response = await api.get<SlotConfiguration[]>(path)
    return orderBy(
      response.data.filter((slotConfig) => slotConfig.isAlwaysFull === false),
      'slotPosition',
      'asc'
    )
  },
  requestForQuotesRegions: async (country: string): Promise<SalesOfficeData[]> => {
    const path = `${REQUEST_FOR_QUOTES_API_PATH}?country=${country}`
    const response = await api.get<SalesOfficeData[]>(path)
    return response.data
  },
  orderCancellationOptions: async (orderId): Promise<OrderCancellationOptions> => {
    const path = `${ORDERS_MANAGEMENT_API_PATH}/Cancel/options/${orderId}`
    const response = await api.get<OrderCancellationOptions>(path)
    return response.data
  },
  cancellationRequestStatus: async (orderId): Promise<CancellationRequestStatus> => {
    const path = `${ORDERS_MANAGEMENT_API_PATH}/Cancel/${orderId}/requests`
    const response = await api.get<CancellationRequestStatus>(path)
    return response.data
  },
  persistedRequestForQuote: async (siteNumber, businessLine) => {
    const path = `distributeduserstorage?keyIdentifier=orderIntake-request-for-quote-${siteNumber}-${businessLine}`
    const response = await api.get<DistributedCacheResponse>(path)

    if (!response.data.value) return

    return JSON.parse(response.data.value)
  },
  notificationSettings: async (): Promise<NotificationSettings[]> => {
    const path = '/user-subscriptions'
    const response = await api.get<NotificationSettings[]>(path)
    return response.data
  },
  scheduledExports: async (): Promise<ScheduledExportType[]> => {
    const path = '/scheduledexports'
    const response = await api.get<ScheduledExportType[]>(path)
    return response.data.sort(
      (a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()
    )
  },
  persistedFilters: async (filterType) => {
    const path = `distributeduserstorage?keyIdentifier=orderIntake-filters-${filterType}`
    const response = await api.get<DistributedCacheResponse>(path)
    if (!response.data.value) return
    return JSON.parse(response.data.value)
  }
}
