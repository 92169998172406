import {api} from '../../App.global'
import {DistributedCacheResponse} from '../../DistributedStorage/type'
import {
  Contact,
  NotificationSettings,
  OrderCancellation,
  OrderIntakePayload,
  OrderRequestResponse,
  PersistedOrderRequest,
  RequestForQuoteResponse
} from '../../OrderIntake/declarations/types'
import {
  PersistedRequestForQuoteFormValues,
  RequestForQuoteFormValues
} from '../../OrderIntake/RequestForQuoteFormValues'
import {
  ORDER_REQUESTS_API_PATH,
  ORDERS_MANAGEMENT_API_PATH,
  REQUEST_FOR_QUOTES_API_PATH
} from '../../Organisms/OrderIntake/OrderIntake.action'
import {FilterDefinition} from '../types'

import {AsyncFn} from './api'

export interface Mutations {
  postOrderRequestDelivery: AsyncFn<
    [
      {
        orderIntakePayload: OrderIntakePayload
        isConfigurableSlots: boolean
        isAutoConfirmed: boolean
      }
    ],
    OrderRequestResponse
  >
  postOrderRequestCollection: AsyncFn<
    [{orderIntakePayload: OrderIntakePayload}],
    OrderRequestResponse
  >
  patchOrderRequest: AsyncFn<[{requestId: string}], OrderRequestResponse>
  persistOrderRequests: AsyncFn<
    [{siteNumber: string; orderRequests: PersistedOrderRequest[]}],
    PersistedOrderRequest
  >
  persistContact: AsyncFn<[{siteNumber: string; contact: Contact}], Contact>
  postRequestForQuote: AsyncFn<[{payload: RequestForQuoteFormValues}], RequestForQuoteResponse>
  cancelOrderRequest: AsyncFn<[{orderId: string; cancelOrderPayload: OrderCancellation}], boolean>
  persistRequestForQuote: AsyncFn<
    [{siteNumber: string; businessLine: string; formValues: PersistedRequestForQuoteFormValues}],
    PersistedRequestForQuoteFormValues
  >
  postNotificationSettings: AsyncFn<
    [{notificationSettings: NotificationSettings}],
    NotificationSettings
  >
  persistFilters: AsyncFn<
    [{filterType: string; filters: FilterDefinition[]}],
    PersistedRequestForQuoteFormValues
  >
  deleteScheduledExports: AsyncFn<[{scheduledExportId: string}], boolean>
}

export const mutations: Mutations = {
  postOrderRequestDelivery: async ({orderIntakePayload, isConfigurableSlots, isAutoConfirmed}) => {
    const response = await api.post<OrderRequestResponse>(
      ORDER_REQUESTS_API_PATH,
      orderIntakePayload,
      {
        params: {
          isConfigurableSlots: isConfigurableSlots,
          isAutoConfirmed: isAutoConfirmed
        }
      }
    )
    return response.data
  },
  postOrderRequestCollection: async ({orderIntakePayload}) => {
    const response = await api.post<OrderRequestResponse>(
      ORDER_REQUESTS_API_PATH,
      orderIntakePayload
    )
    return response.data
  },
  patchOrderRequest: async ({requestId}) => {
    const response = await api.patch<OrderRequestResponse>(
      `${ORDER_REQUESTS_API_PATH}/${requestId}`
    )
    return response.data
  },
  persistOrderRequests: async ({siteNumber, orderRequests}) => {
    const response = await api.post<DistributedCacheResponse>('distributeduserstorage', {
      keyIdentifier: `orderIntake-orderRequests-${siteNumber}`,
      value: JSON.stringify(orderRequests)
    })
    return JSON.parse(response.data.value)
  },
  persistContact: async ({siteNumber, contact}) => {
    const response = await api.post<DistributedCacheResponse>('distributeduserstorage', {
      keyIdentifier: `orderIntake-contact-${siteNumber}`,
      value: JSON.stringify(contact)
    })
    return JSON.parse(response.data.value)
  },
  postRequestForQuote: async ({payload}) => {
    const response = await api.post<RequestForQuoteResponse>(REQUEST_FOR_QUOTES_API_PATH, payload)
    return response.data
  },
  cancelOrderRequest: async ({orderId, cancelOrderPayload}) => {
    const response = await api.post<boolean>(
      `${ORDERS_MANAGEMENT_API_PATH}/Cancel/${orderId}`,
      cancelOrderPayload
    )
    return response.data
  },
  persistRequestForQuote: async ({siteNumber, businessLine, formValues}) => {
    const response = await api.post<DistributedCacheResponse>('distributeduserstorage', {
      keyIdentifier: `orderIntake-request-for-quote-${siteNumber}-${businessLine}`,
      value: JSON.stringify(formValues)
    })
    return JSON.parse(response.data.value)
  },
  postNotificationSettings: async ({notificationSettings}) => {
    const response = await api.put<NotificationSettings>(
      '/user-subscriptions',
      notificationSettings
    )
    return response.data
  },
  persistFilters: async ({filterType, filters}) => {
    const response = await api.post<DistributedCacheResponse>('distributeduserstorage', {
      keyIdentifier: `orderIntake-filters-${filterType}`,
      value: JSON.stringify(filters)
    })
    return JSON.parse(response.data.value)
  },
  deleteScheduledExports: async ({scheduledExportId}) => {
    await api.delete<boolean>(`scheduledExports/${scheduledExportId}`)
    return true
  }
}
